<template>
  <div ref="body">
    <custom-header active="design" :subActive="current"></custom-header>

    <div class="content-container ">
      <div class="left-bar ">
        <div class="title text-bold">Exhibitions</div>
        <div class="main-menu">
          <div class="row">
            <template v-for="(item, idx) in themes">
              <div :key="item.partsId" :class="['theme', themeObj.partsId == item.partsId ? 'active' : '']" @click="switchTheme(item.partsId, idx)">
                <div class="theme-item">{{item.partsName}}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div>
        <h1 v-show="themeObj.showType == 1" class="theme-title">{{themeObj.partsName}}</h1>
        <div v-show="themeObj.showType == 1" class="theme-content">
          <div v-if="themeObj.type == 1" v-html="themeObj.content"></div>
          <div v-if="themeObj.type == 2">
            <template v-for="(img, idx) in themeObj.imgList">
              <img :src="img" :key="idx">
            </template>
          </div>
          <div v-if="themeObj.type == 3">
            <img :src="themeObj.networkImage" :alt="themeObj.partsName">
          </div>
        </div>
 
        <!--显示产品品类信息--->
        <div v-show="themeObj.showType == 2" class="exh-content">
          <div class="list">
            <van-empty v-if="exhibitors.length === 0" description="" />
            <template v-else>
              <div class="flex flex-wrap">            
                <div class="exhibitor" v-for="(item, i) in exhibitors" :key="i" @click="showDetail(item)">
                  <div class="exhibitor-cover">
                    <img :src="item.banners+'?x-oss-process=image/resize,w_542,h_308,p_50'" :alt="item.brandNameEn || item.brandName" class="fill block">
                  </div>
                  <div>
                    <div class="name text-bold">{{ item.brandNameEn || item.brandName }}</div>
                  </div>
                </div>
              </div>
              <div class="pagination-box flex justify-center">
                <el-pagination background layout="prev, pager, next" :hide-on-single-page="pages <= 1" :page-size="pageSize" :total="total" @current-change="handleCurrentChange"></el-pagination>
              </div>
            </template>
          </div>
        </div>

        <van-overlay :show="show" z-index="10"  @click="closeDetail">
          <div class="" @click.stop>
            <div class="exh-box bg-white scrollBar">
              <div class="exh-title text-bold text-center">{{ exhInfo.brandNameEn || exhInfo.brandName || exhInfo.companyNameEn || exhInfo.companyName }}</div>
              <div class="">
                <!-- <img :src="exhInfo.banners" class="exh-banner" /> -->
                <!--40届增加产品图片轮播--->
                <van-swipe class="exh-banner" :loop="false" :autoplay="4000" :show-indicators="false">
                  <van-swipe-item v-for="item in dailogBanner" :key="item">
                    <a class="item1 text-center" >
                      <!-- x-oss-process=image/resize,limit_0,m_fill,w_542,h_200/quality,q_100 -->
                      <img :src="item + '?x-oss-process=image/resize,w_542,h_308,p_50'" class="exh-banner">
                    </a>
                  </van-swipe-item>
                </van-swipe>
                <!--40届增加产品图片轮播--->
              </div>
              <!-- <div v-if="exhInfo.positionNumber" class="position">展位号: {{exhInfo.positionNumber}}</div> -->
              <div v-if="exhInfo.phone" class="phone"><span class="text-bold">Contact No:</span> 0086 {{exhInfo.phone}}</div>
              <div v-if="exhInfo.mailbox" class="phone"><span class="text-bold">Email:</span> {{exhInfo.mailbox}}</div>
              <div v-if="exhInfo.qrCode" class="contact">
                <img :src="exhInfo.qrCode" class="exh-qrcode" />
                <div class="desc text-center">Company QR Code</div>
              </div>
              <div class="mail-box">
                <div class="noties">If you have any enquiry, please do not hesitate to contact us by:</div>
                <!-- <div><a href="mailto:business-cooperation.szfa@outlook.com">business-cooperation.szfa@outlook.com</a></div> -->
                <div><a href="mailto:business-cooperation@szfa.com">business-cooperation@szfa.com</a></div>
              </div>
              <!-- <div class="desc">{{exhInfo.companyDesc}}</div> -->
            </div>
          </div>
        </van-overlay>
        <!----->

      </div>
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>

import { Swipe, SwipeItem, Icon, Empty, Search, Overlay } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getThemeList } from '@/api/index.js';
import { getPavilionList, getExhibitorByCategory, getExhibitorDetail } from '@/api/index.js'

export default {
  // name: '深圳国际家具展',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Empty.name]: Empty,
    [Search .name]: Search ,
    [Overlay.name]: Overlay,
  },
  mixins: [commonMixin],
  metaInfo: {
    title: 'Shenzhen International Home Furnishing Exhibition: Themes', // set a title
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week' },
      { name: 'description', content: 'Shenzhen International Furniture Exhibition & Creative Week, March 16-19, 2025 : Learn About the Expo Themes & the Hall Number of the Respective Expos! '},
    ],
  },
  data() {
    return {
      current: 'theme',
      partsId: '',
      themes: [],
      themeObj: {},

      /***********展馆品类******** */
      exhInfo: {},
      show:false,
      dailogBanner:[],
      exhibitors:[],

      pageNo: 1,
      pageSize: 20,
      total: 0,
      pages: 1,
    }
  },
  created() {
    this.partsId = this.$route.query.id || '';
    this.getThemes();
  },
  methods: {
    switchTheme(partsId, idx) {
      this.partsId = partsId;
      this.themeObj = this.themes[idx];
      this.queryCategory()
    },
    getThemes() {
      getThemeList().then(res => {
        let list = res.resultData || [];
        // 过滤指定的主题
        list = list.filter(e => !['ON LIGHT', 'Workplace', 'Design Inspiration'].includes(e.partsName));
        
        this.themes = list.map(e => {
          if (e.type == 2) {
            e.imgList = e.batchImage.split(',,,');
          }
          return e;
        });
        if (this.themes.length > 0) {
          if (this.partsId) {
            this.themeObj = this.themes.find(e => e.partsId == this.partsId)
          } else{
            this.themeObj = this.themes[0]
            this.partsId = this.themes[0].partsId
          }
          this.queryCategory()
        }
      })
    },
    
    /***********展馆品类******** */
    queryCategory() {
      if(this.partsId == 1 || this.partsId== 4 || this.partsId== 6 ) {
        this.themeObj.showType = 2
      } else 
        this.themeObj.showType = 1

      //*************  一群不懂业务的老板，把代码写乱了 ************ */
      if(this.partsId == 1) {
        this.getPavilions(333)
      } else if(this.partsId == 4) {
        this.getPavilions(334)
      } else if( this.partsId == 6) {
        this.getPavilions(335)
      }
    },
    closeDetail() {
      this.exhInfo = {}
      this.show = false;
    },
    // 获取展馆列表
    getPavilions(categoryId) {
      getPavilionList(categoryId).then(res => {
         let pavilions = res.resultData || [];
          let pavilionIdList = pavilions.map(e => e.pavilionId).join(',');
          this.getExhibitors(pavilionIdList)
        
      })
    },
    // 获取展商列表
    getExhibitors(pavilionIdList) {
      let params = {
        keyword: null,
        pageNo: 1,
        pageSize: 20,
        // categoryId: this.categoryId,
        // pavilionId: this.pavilionId,
        pavilionIdList: pavilionIdList,
        exh: this.exh,
      };
      getExhibitorByCategory(params).then(res => {
        let {records = [], total = 0, pages = 0 } = res.resultData;
        this.total = total;
        this.pages = pages;
        this.exhibitors = records.map((v) => {
          v.mainAudience = v.mainAudience.replaceAll(';', '；');
          return v;
        });
      })
    },
    showDetail(row) {
      getExhibitorDetail(row.exhibitorId).then(res => {
        let exhInfo = res.resultData || {};
        this.exhInfo = exhInfo;
        this.dailogBanner = []

        this.dailogBanner.push(this.exhInfo.banners)
        let pics = this.exhInfo.productPictures.split(';');
        pics.map(e=>{
          this.dailogBanner.push(e)
        })

        console.log(this.dailogBanner)
        
        this.show = true;
      })
    },
  },
}
</script>


<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    .left-bar {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
    .title {
      font-size: 16px;
      margin-bottom: 10px;
      text-align: center;
    }
    
    .main-menu {
      width: 100%;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 0;
      }
      
      .row {
        width: auto;
        white-space: nowrap; 
        
        .theme {
          display: inline-block;
          min-width: 160px;
          cursor: pointer;
          text-align: center;
          border-radius: 4px;
          border: 1px solid #fff;
          line-height: 30px;
          padding: 0 10px;
          
          &.active {
            border-color: #060211;
          }
          .theme-item {
            font-weight: normal;
            font-size: 13px;
            font-family: 'Poppins-Medium';
          }
          
        }
        
      }
    }
    
    .theme-title {
      font-size: 16px;
      text-align: center;
      margin: 20px auto 10px;
    }
    .theme-content {
      width: 375px;
      padding: 10px 17px;
      font-size: 14px;
      
      /deep/ img {
        display: block;
        width: 100%;
        margin: 5px auto;
        height: auto;
        border-radius: 4px;
      }
      
    }
  }
  
  .exh-content {
      width: 375px;
      
      .list {
        width: 100%;
        padding: 0 5px;
        
        /deep/ .van-empty__image {
          width: 100%;
          height: auto;
        }
        
        .exhibitor {
          width: 172px;
          margin: 0 5px 8px;
          padding-bottom: 2px;
          
          .exhibitor-cover {
            display: block;
            // width: 172px;
            // height: 172px;
            width: 172px;
            height: 100px;
            border-radius: 4px;
            overflow: hidden;
          }
          
          .name {
            width: 100%;
            padding: 5px;
            font-size: 14px;
            line-height: 2em;
          }
          
          .intro {
            font-size: 12px;
            padding: 0 5px;
            margin-bottom: 5px;
          }
          
          .tagBox {
            display: flex;
            padding: 0 2px;
            
            &-item {
              margin-right: 5px;
              padding: 5px 6px;
              border-radius: 4px;
              font-size: 11px;
              color: #fefefe;
              text-align: center;
              &:last-child {
                margin-right: 0;
              }
            }
            &-item1 {
              background: #c29441;
            }
            &-item2 {
              background-color: #22418c;
            }
          }
        }
        
      }
      
      .pagination-box {
        margin-top: 20px;
        
        /deep/ .el-pagination.is-background {
          
          .btn-next .el-icon, 
          .btn-prev .el-icon {
            font-size: 14px;
            height: 24px;
            line-height: 24px;
          }
          
          .btn-next,
          .btn-prev, 
          .el-pager li {
            margin: 0 1px;
            color: #606266;
            min-width: 24px;
            border-radius: 4px;
            padding: 0 5px;
            font-size: 14px;
            height: 24px;
            line-height: 24px;
          }
          
          .el-pager li:not(.disabled).active {
            background-color: #409EFF;
            color: #FFF;
          }
          
          .el-pager .more::before {
            line-height: 24px;
          }
        }
      
      }
      
    }
  
    .exh-box {
      position: absolute;
      z-index: 11;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      max-height: 85vh;
      width: 360px;
      padding: 10px;
      border-radius: 4px;
      overflow: auto;
      
      .exh-title {
        font-size: 16px;
      }
      
      .position {
        color: #aaa;
        font-size: 12px;
        margin-top: 5px;
      }
      
      .exh-banner {
        display: block;
        width: auto;
        height: 500px;
        margin: 10px auto;
        border-radius: 4px;
      }
      
      .phone {
        font-size: 14px
      }
      .exh-qrcode {
        display: block;
        width: 150px;
        height: auto;
        margin: 10px auto 0;
      }
      
      .mail-box {
        margin: 10px 0;
        
        .noties {
          font-size: 14px;
        }
        
        a {
          font-size: 14px;
          color: #409EFF;
        }
      }
      
      .desc {
        font-size: 13px!important;
        line-height: 1.6em;
      }
    }

  }
  

// PC
@media screen and (min-width: 1024px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    display: flex;
    width: 280px;
    margin: 0 auto 20px;
    
    .left-bar {
      width: 80px;
      padding: 10px;
      box-sizing: border-box;
    }
    .title {
      font-size: 6.5px;
      margin-bottom: 7px;
      text-align: center;
      
    }
    .theme {
      width: 60px;
      margin: 2px 0;
      cursor: pointer;
      text-align: center;
      border-radius: 1px;
      border: 0.5px solid #fff;
      // line-height: 12px;
      line-height: 6px;
      padding: 3px;
      
      &.active,
      &:hover {
        border-color: #060211;
      }
      .theme-item {
        font-weight: normal;
        font-size: 3.6px;
        font-family: 'Poppins-Medium';
      }
      
    }
    
    .theme-title {
      font-size: 6px;
      text-align: center;
      margin-top: 10px;
    }
    .theme-content {
      width: 180px;
      margin-left: 10px;
      padding: 10px 0;
      font-size: 4.5px;
      
      /deep/ img {
        // display: block;
        // width: 90%;
        // margin: 5px auto;
        display: inline-block;
        margin: 0 5% 6px 0;
        height: auto;
        border-radius: 1px;
        
        &:nth-child(3n) {
          margin-right: 0;
        }
      }
      
      /deep/ video {
        border-radius: 1px;
      }
      
    }
  }
  
  .exh-content {
        margin-top: 10px;
        width: 230px;
        
        .pavilion-box2 {
          margin-bottom: 7px;
          
          .item {
            margin: 0 1px 1px;
            cursor: pointer;
            text-align: center;
            background-color: #fff;
            
            &.active {
              .option {
                background-color: #060211;
                color: #fff;
              }
            }
            .option {
              height: 10px;
              line-height: 10px;
              font-weight: normal;
              font-size: 3.6px;
              padding: 0 4px;
              border-radius: 3Px;
              
              &:hover {
                background-color: #060211;
                color: #fff;
              }
            }
            
          }
          
        }
      
        .list {
          
          /deep/ .van-empty__image {
            width: 40%;
            height: auto;
          }
        }
        
        .exhibitor {
          width: 53px;
          margin: 0 5px 6px 0;
          padding-bottom: 2px;
          border-radius: 1px;
          cursor: pointer;
          
          &:nth-child(4n) {
            margin-right: 0;
          }
          
          &:hover {
            box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
            
            .exhibitor-cover {
              border-radius: 1px 1px 0 0;
            }
          }
          
          .exhibitor-cover {
            display: block;
            // width: 53px;
            // height: 53px;
            width: 53px;
            height: 30px;
            border-radius: 1px;
            overflow: hidden;
            margin-bottom: 2px;
          }
          
          .name {
            width: 100%;
            padding: 2px;
            margin-bottom: 1px;
            font-size: 3.6px;
          }
          
          .intro {
            font-size: 3.2px;
            padding: 0 2px;
            margin-bottom: 2px;
          }
          
          .tagBox {
            display: flex;
            padding: 0 2px;
            
            &-item {
              // max-width: 10em;
              margin-right: 2px;
              padding: 1px 2px;
              border-radius: 1px;
              font-size: 3px;
              color: #fefefe;
              text-align: center;
              &:last-child {
                margin-right: 0;
              }
            }
            &-item1 {
              background: #c29441;
            }
            &-item2 {
              background-color: #22418c;
            }
          }
        }
        
        /deep/ .el-pagination.is-background {
          
          .btn-next .el-icon, 
          .btn-prev .el-icon {
            font-size: 3.6px;
            font-weight: 700;
            height: 8px;
            line-height: 8px;
          }
          
          .btn-next,
          .btn-prev, 
          .el-pager li {
            margin: 0 1px;
            color: #606266;
            min-width: 8px;
            border-radius: 1px;
            padding: 0 1px;
            font-size: 3.6px;
            height: 8px;
            line-height: 8px;
          }
          
          .el-pager li:not(.disabled).active {
            background-color: #409EFF;
            color: #FFF;
          }
          
          .el-pager .more::before {
            line-height: 8px;
          }
        }
  
      }
    
      .exh-box {
      position: absolute;
      z-index: 11;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 125px;
      // max-width: 800Px;
      // min-width: 500Px;
      max-height: 80vh;
      padding: 3px 5px;
      border-radius: 2px;
      overflow: auto;
      
      .exh-title {
        font-size: 5px;
      }
      
      .position {
        color: #aaa;
        font-size: 3.6px;
        margin-top: 1px;
      }
      .contact {
        font-size: 4px;
        margin-top: 1px;
      }
      
      .exh-banner {
        display: block;
        width: 70%;
        height: auto;
        margin: 2px auto;
        border-radius: 1px;
      }
      
      .phone {
        width: 70%;
        font-size: 4px;
        // text-align: center;
        margin: 0 auto;
      }
      
      .exh-qrcode {
        display: block;
        width: 40px;
        height: auto;
        margin: 0 auto;
      }
      
      .mail-box {
        margin: 3px 0;
        line-height: 1.2;
        
        .noties {
          font-size: 3.2px;
        }
        
        a {
          font-size: 3.2px;;
          color: #409EFF;
        }
      }
      .desc {
        font-size: 3.2px!important;
        line-height: 4.8px;
      }
    }
  }
    


</style>

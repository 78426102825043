<template>
  <div ref="body" class="body">
    
    <div class="bodyHeight">
      <custom-header active="home"></custom-header>
      
      <!-- 移动 -->
      <div class="date text-center text-bold">
        <span class="block">March 16-19, 2025</span>
        <span class="block">Shenzhen World Exhibition and Convention Center</span>
      </div>
      <!-- <div class="sign-btn-box1">
        <a href="https://join.szcreativeweek.com/admin/join" target="_blank" class="button text-medium" rel="noopener noreferrer">I want to exhibit</a>
        <a href="https://reg39.szcreativeweek.com/h5/code?l=A45043-513E49" class="button text-medium" target="_blank" rel="noopener noreferrer">Pre-register now</a>
      </div> -->
      
      <!-- banner -->
      <div class="banner-container relative">
        <van-swipe ref="banner" :autoplay="5000" class="fill">
          <van-swipe-item v-for="(item, idx) in banners" :key="idx">
            <div class="fill" style="position: relative;">
              <!-- <a v-if="item.url" target="_blank" :href="item.url" rel="noopener noreferrer">
                <img :src="item.bannerPicture + '?x-oss-process=image/resize,p_100'" alt="" style="width: 100%; height: auto;">
              </a> -->
              <img :src="item.bannerPicture + '?x-oss-process=image/resize,p_100'" alt="" style="width: 100%; height: auto;">

              <!--40届需要点击按钮跳转-->
              <a v-if="item.url" target="_blank" :href="item.url" rel="noopener noreferrer">
                <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/banner40-button.png" alt="" style="position:absolute;width: 26.01%; height: 12.67%; top:61.23%;left:58.13%;">
              </a>
            </div>
          </van-swipe-item>
          <template v-if="banners.length > 1" #indicator>
            <div class="custom-banner-indicator">
              <van-icon name="down" class="btn-box left" @click="turnLeft('banner')" />
              <van-icon name="down" class="btn-box right" @click="turnRight('banner')" />
            </div>
          </template>
        </van-swipe>
        
        <!-- <div class="sign-btn-box absolute">
          <a href="https://join.szcreativeweek.com/admin/join" target="_blank" class="button text-bold" rel="noopener noreferrer">I want to exhibit</a>
          <a href="https://reg39.szcreativeweek.com/h5/code?l=A45043-513E49" class="button text-bold" target="_blank" rel="noopener noreferrer">Pre-register now</a>
        </div> -->
      </div>
    </div>
    
    <!-- 展会介绍 -->
    <div class="intro-section flex flex-wrap">
      <div  class="intro">
        <h1 class="text-bold">Shenzhen International Furniture Exhibition</h1>
        <div class="">
          <!-- Shenzhen International Furniture Exhibition (SIFE) and Shenzhen Creative Week (SZCW) was established in 1996. Adhering to "design-oriented, trendsetting, continuous innovation", the fair utilizes design to connect and integrate with urban culture. SIFE and SZCW have increasingly become a window for the furniture and design industries to understand the city—between 2015 and 2022, the fair saw visitors grow from 79,061 to 187,546 and exhibitors from 243 to 684 respectively. Now covering an exhibition area of 250,000 m2, it has evolved into a strategic platform for connecting international design resources with Chinese manufacturing and facilitating Chinese manufacturing to access international and domestic markets. Through implementing high standards, Shenzhen Creative Week drives the high-quality development of China’s home furnishing industry. -->
          <p style="margin-bottom: 10px;">This March, South China kicks off the most important time of the year for sourcing furniture and home interiors products from China’s home interiors industry. The Shenzhen International Furniture Exhibition (SIFEChina) remains the fair destination featuring the most well-established brands, innovative products, spanning the entire home interiors market.</p>
          <p style="margin-bottom: 10px;">Under Shenzhen Creative Week and held concurrently with SIFEChina are an additional 4 major exhibitions. 2025 will hold an expanded customized furniture segment and see an expanded Smart Home offering featuring collaborations of leading furniture brands and Shenzhen’s tech sector. </p>
          <p>Register today and take part in what is and remains South China’s most innovative design led trade event for the home interiors industry!</p>
        </div>
      </div>
      <!-- <div class="intro-img flex justify-between">
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/02.jpg" alt="" class="intro-img-left">
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/01.jpg" alt="" class="intro-img-right">
      </div> -->
      <div class="top-video">
        <van-swipe ref="banner" :autoplay="5000" class="fill">
          <van-swipe-item v-for="(item, idx) in envideoImage" :key="idx">
            <div class="fill" style="position: relative;">
              <img :src="item + '?x-oss-process=image/resize,p_100'" alt="" style="width: 100%; height: auto;">
            </div>
          </van-swipe-item>
          <template v-if="envideoImage.length > 1" #indicator>
            <div class="custom-banner-indicator">
              <van-icon name="down" class="btn-box left" @click="turnLeft('banner')" />
              <van-icon name="down" class="btn-box right" @click="turnRight('banner')" />
            </div>
          </template>
        </van-swipe>
        <!-- <video id="top-video" ref="topVideo" autoplay muted controls class="fill" src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/%E8%8B%B1%E6%96%87%E7%89%88-SZCW%E5%AE%98%E5%AE%A3%E5%AE%8C%E6%95%B4%E7%89%88%E8%A7%86%E9%A2%91.mov" type="video/mp4"></video> -->
      </div>
    </div>
    
    <!-- 展商品类 -->
    <!-- <div v-if="exhibitionCategorys.length > 0" class="section-box bg-white">
      <div class="title">Exhibitor Categories</div>
      <div v-for="category in exhibitionCategorys" :key="category.id" class="category-box">
        <template v-if="category.imgs.length > 0">
          <div class="category-name">{{category.name}}</div>
          <div class="category-img-list flex">
            <div v-for="(img, idx) in category.imgs" :key="idx" class="category-img-box">
              <img :src="img" alt="" class="category-img block">
            </div>
          </div>
        </template>
      </div>
    </div> -->
    
    <!-- 家具展商 / 展商阵容: pc、 移动 -->
    <!-- <div class="lineup-box section">
      <div class="section-container relative" style="width: 100%;">
        <div class="title">Exhibitor Lineup</div>
        <van-swipe ref="exh" class="scroll-contianer" :loop="false" :width="500" @change="exhChange" :show-indicators="false">
          <van-swipe-item v-for="(item, idx) in lineupList" :key="idx">
            <div class="item1 text-center">
              <a v-if="item.url" target="_blank" :href="item.url" rel="noopener noreferrer">
                <img :src="item.bannerPicture + '?x-oss-process=image/resize,p_50'" alt="" class="exh-img radius1">
                <div class="item-title">{{item.title}}</div>
              </a>
              <template v-else>
                <img :src="item.bannerPicture + '?x-oss-process=image/resize,p_50'" alt="" class="exh-img radius1">
                <div class="item-title">{{item.title}}</div>
              </template>
            </div>
          </van-swipe-item>
          <template #indicator>
            <div class="turn-btn-box absolute flex justify-between">
              <van-icon name="down" class="btn-box1 left" @click="turnLeft('exh')" />
              <van-icon name="down" class="btn-box1 right" @click="turnRight('exh')" />
            </div>
          </template>
        </van-swipe>
        <van-swipe class="scroll-contianer1" :loop="false" :show-indicators="false">
          <van-swipe-item v-for="(item, idx) in lineupList" :key="idx">
            <div class="item1 text-center">
              <a v-if="item.url" target="_blank" :href="item.url" rel="noopener noreferrer">
                <img :src="item.bannerPicture + '?x-oss-process=image/resize,p_50'" alt="" class="exh-img radius4">
                <div class="item-title">{{item.title}}</div>
              </a>
              <template v-else>
                <img :src="item.bannerPicture + '?x-oss-process=image/resize,p_50'" alt="" class="exh-img radius4">
                <div class="item-title">{{item.title}}</div>
              </template>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div> -->
    
    <!-- 八大主题展: pc、 移动 -->
    <div class="section bg-white">
      <div class="theme-container relative" style="width: 100%;">
        <div class="theme-title">Concurrent Exhibitions</div>
        <van-swipe ref="theme-exh" class="theme-scroll-contianer" :loop="false" :width="500" @change="themeChange" :show-indicators="false">
          <van-swipe-item v-for="item in themes" :key="item.partsId">
            <a class="item1 text-center" :href="'/theme?id=' + item.partsId">
              <img :src="item.img + '?x-oss-process=image/resize,p_100'" :alt="item.partsName" class="exh-img radius1">

              <el-button style="width:100px;font-size:16px;position:absolute; right: 10px;top:10px;height: 40px;padding:10px;background-color: rgb(0,175,165);border:0px;border-radius:5px;margin-right: 10px;color:white;">See more</el-button>
            </a>
          </van-swipe-item>
          <template #indicator>
            <div class="turn-btn-box absolute flex justify-between">
              <van-icon name="down" class="btn-box left" @click="turnLeft('theme-exh')" />
              <van-icon name="down" class="btn-box right" @click="turnRight('theme-exh')" />
            </div>
          </template>
        </van-swipe>
        <van-swipe class="scroll-contianer1" :loop="false" :autoplay="4000" :show-indicators="false">
          <van-swipe-item v-for="item in themes" :key="item.partsId">
            <a class="item1 text-center" :href="'/theme?id=' + item.partsId">
              <img :src="item.img + '?x-oss-process=image/resize,p_100'" :alt="item.partsName" class="exh-img radius4">
            </a>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
    
    <!-- 展会平面图 -->
    <div class="section bg-white flex flex-direction align-center">
      <div class="title-box">
        <div class="map-title text-center">Guide Map</div>
        <img :src="'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/SZCW_3DMAP20250106%20.jpg'" alt="guide map" class="block" style="width: 80%; height: auto; margin: 0 auto;">
      </div>
      <div class="title-box1">
        <div class="title">Guide Map</div>
        <img :src="'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/SZCW_3DMAP20250106%20.jpg'" alt="guide map" style="width: 100%; height: auto;">
      </div>
    </div>
    
    <!-- 展会视频 -->
    <div class="bg-white" >
      <div class="relative flex flex-direction align-center justify-center" style="width: 100%;">
        <!-- <div class="title-box">
          <div class="map-title text-center">Exhibition Video</div>
        </div>
        <div class="title-box1">
          <div class="title">Exhibition Videos</div>
        </div> -->
        <div class="video-container">
          <video id="my-video" ref="myVideo" controls class="fill">
            <source src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/2024-39.mp4" type="video/mp4">
          </video>
        </div>
      </div>
    </div>
    
    <!-- 开展倒计时 -->
    <!-- <div class="section" style="background-image: linear-gradient(-180deg, #002230, #0d0d0d);"> -->
    <!-- <div class="section">
      
      <div class="downcount-box">
        <div class="day">
          <span class="downcount-day">{{day}}</span>
          <span>Days</span>
        </div>
        <span class="noties block text-center">Until the 39th Shenzhen International Furniture Exhibition</span>
        <span class="noties block text-center">and Shenzhen Creative Week</span>
        
      </div>
      
      <div class="sign-btn-box2 flex">
        <a href="https://join.szcreativeweek.com/admin/join" target="_blank" class="button text-bold" rel="noopener noreferrer">I want to exhibit</a>
        <a href="https://reg39.szcreativeweek.com/h5/code?l=A45043-513E49" class="button text-bold" target="_blank" rel="noopener noreferrer">Pre-register now</a>
      </div>
      
    </div> -->
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>
 
<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getHomeBanner, getThemeList, getExternalList, getExhibitionCategoryList } from '@/api/index.js';

export default {
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter,
    'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  // SEO: 设置页面keyWords、description
  metaInfo: {
    title: 'Shenzhen-China International Furniture Exhibition & Creative Week', // 页面标题
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week' },
      { name: 'description', content: 'China Shenzhen Creative Week & the 40th Shenzhen International Furniture Expo Will Be Held at the Shenzhen Exhibition & Convention Center From March 16-19, 2025.'},
      // { name: 'viewport', content: 'width=device-width, user-scalable=yes, initial-scale=1.0, maximum-scale=3.0, minimum-scale=1.0' },
    ],
    // link: [
    //   {rel: 'icon', href: '/favicon.ico'},
    //   {rel: 'stylesheet', href: 'https://xxxxxxx.com/style.css', type: 'text/css'},
    // ],
  },
  data() {
    return {
      day: 1,
      banners: [],
      lineupList: [],
      themes: [],
      activities: [],
      forums: [],
      newsList1: [],
      newsList2: [],
      forumGuests: [],
      exhibitionCategorys: [],
      // friendLink: [],
      observer: null,
      observer1: null,

      envideoImage:['https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-01.jpg',
      'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-02.jpg',
      'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-03.jpg',
      'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-04.jpg',
      'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-05.jpg',
      'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-06.jpg',
      'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-07.jpg',
      'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-08.jpg',
      'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-09.jpg',
      'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-10.jpg',
      'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-11.jpg',
      'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-12.jpg',
      'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/home/en-video-13.jpg']
      
    }
  },
  created() {
    this.getDay();
    this.getBanners(); // 获取banner
    this.getExhibitionCategorys(); // 获取八大主题展
    this.getThemes(); // 获取八大主题展
    // this.getLineup(); // 获取家具展商/展商阵容
    // this.getFriendLink();
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.8, // 设置阈值为80%，即视频可见面积超过50%触发播放
    });
    this.observer.observe(this.$refs.myVideo);
    this.observer1 = new IntersectionObserver(this.handleIntersection1, {
      root: null,
      rootMargin: '0px',
      threshold: 0.8, // 设置阈值为80%，即视频可见面积超过50%触发播放
    });
    this.observer1.observe(this.$refs.topVideo);
  },
  destroyed () {
    let topVideoElement = document.querySelector('#top-video');
    if (topVideoElement) {
      // 销毁 VIDEO 元素
      topVideoElement.pause(); // 停止播放
      topVideoElement.removeAttribute('src'); // 清空srcs属性来停止加载
      topVideoElement.load(); // 调用 load() 方法，以确保所有相关数据都被卸载。
      // 将 VIDEO 元素的事件监听器设为 null，以避免内存泄漏。
      topVideoElement.oncanplaythrough = null;
      topVideoElement.onerror = null;
      topVideoElement.remove();
      topVideoElement = null; // 最后将 VIDEO 元素赋值为 null，以释放其占用的内存。
    }
    let videoElement = document.querySelector('#my-video');
    if (videoElement) {
      // 销毁 VIDEO 元素
      videoElement.pause(); // 停止播放
      videoElement.removeAttribute('src'); // 清空srcs属性来停止加载
      videoElement.load(); // 调用 load() 方法，以确保所有相关数据都被卸载。
      // 将 VIDEO 元素的事件监听器设为 null，以避免内存泄漏。
      videoElement.oncanplaythrough = null;
      videoElement.onerror = null;
      videoElement.remove();
      videoElement = null; // 最后将 VIDEO 元素赋值为 null，以释放其占用的内存。
    }
  // },
  // beforeUnmount() {
    this.observer.unobserve(this.$refs.myVideo);
    this.observer = null;
    this.observer1.unobserve(this.$refs.topVideo);
    this.observer1 = null;
  },
  methods: {
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 视频元素进入可视区域，播放视频
          // this.$refs.topVideo.pause();
          this.$refs.myVideo.play();
        } else {
        // 视频元素离开可视区域，暂停视频
        this.$refs.myVideo.pause();
        }
    
      });
    },
    handleIntersection1(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // 视频元素进入可视区域，播放视频
          this.$refs.topVideo.play();
        } else {
        // 视频元素离开可视区域，暂停视频
        this.$refs.topVideo.pause();
        }
    
      });
    },
    // 获取banner
    getBanners() {
      getHomeBanner({bannerType: 'WEBSITE'}).then(res => {
        if (res.resultStatus) {
          this.banners = res.resultData;
          this.banners[0].bannerPicture = 'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/banner40-10.jpg'
          this.banners[0].url='https://reg40.szcreativeweek.com/h5/code?l=5C8523-E1A-F9'
        }
      })
    },
    // 获取展商品类列表
    getExhibitionCategorys() {
      getExhibitionCategoryList().then((res) => {
        if (res.resultStatus) {
          let list = (res.resultData || []).map(e => {
            e.imgs = e.img ? e.img.split(', ') : [];
            return e
          });
          this.exhibitionCategorys = list;
        }
      });
    },
    // 获取家具展商/展商阵容
    getLineup() {
      getHomeBanner({bannerType: 'LINEUP'}).then((res) => {
        if (res.resultStatus) {
          this.lineupList = res.resultData;
        }
      });
    },
    // 获取八大主题展
    getThemes() {
      getThemeList().then(res => {
        this.themes = (res.resultData || []).filter(e => e.partsId != 8)
      })
    },
    // 链接跳转
    urlLink(url) {
      url && (window.location = url);
    },
    exhChange(e) {
      console.log(e, this.lineupList.length - 2);
      if (e == this.lineupList.length - 2) {
        this.swipeToFirst('exh');
      }
    },
    themeChange(e) {
      if (e == 7) {
        // this.swipeToFirst('theme-exh');
      }
    },
    activeChange(e) {
      if (e == 4) {
        // this.swipeToFirst('active');
      }
    },
    bbsChange(e) {
      if (e == 6) {
        // this.swipeToFirst('bbs');
      }
    },
    exhNewsChange(e) {
      if (e == 1) {
        // this.swipeToFirst('exh-news');
      }
    },
    guestChange(e) {
      if (e == 1) {
        // this.swipeToFirst('guest');
      }
    },
    // 切换到轮播第一项
    swipeToFirst(name) {
      setTimeout(() => {
        this.$refs[name].swipeTo(0);
      }, 5000);
    },
    getDay() {
      let start = new Date(), end = new Date('2025-03-16');
      this.day = parseInt((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24))
    },
    turnLeft(key) {
      this.$refs[key].prev();
    },
    turnRight(key) {
      this.$refs[key].next();
    },
    // 跳转到活动详情页
    toActiveDetail(id) {
      // this.$router.push({
      //   path: '/activityDetail',
      //   query: {
      //     id
      //   }
      // })
    },
    // 跳转到论坛详情页
    toForumDetail(id) {
      // this.$router.push({
      //   path: '/bbsDetail',
      //   query: {
      //     id
      //   }
      // })
    },
    // 获取友情链接列表
    getFriendLink() {
      getExternalList().then(res => {
        let list = res.resultData || [];
        this.friendLink = list;
      })
    },
  },
}
</script>

<style scoped lang="less">
.radius6Px {
  border-radius: 6Px!important;
}

@purplish-red: #E4007F; // 紫红色
@cyan: #00ABA5; // 紫红色
  
// 移动端
@media screen and (max-width: 1023px) {
  
  .date {
    border-top: 1px solid #eee;
    padding: 10px 0;
  }
  
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
    
    .custom-banner-indicator {
      display: none;
    }
    
  }

  .sign-btn-box {
    display: none;
  }
  .sign-btn-box2 {
    font-family: 'Poppins-Light';
    justify-content: center;
    margin-bottom: 15px;
    
    .button {
      display: block;
      background-color: @purplish-red;
      color: #fff;
      border: none;
      width: 140px;
      padding: 10px 0;
      text-align: center;
      margin: 6px 10px;
      font-size: 14px;
      border-radius: 4px;
      cursor: pointer;
    }
    .reg-btn {
      background-color: @cyan;
      color: #fff;
    }
  }
  
  .sign-btn-box1 {
    font-family: 'Poppins-Light';
    display: flex;
    justify-content: center;
    
    .button {
      width: 140px;
      height: 30px;
      line-height: 30px;
      border: none;
      background-color: @purplish-red;
      color: #fff;
      margin: 20px 8px;
      font-size: 12px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
    }
    .reg-btn {
      background-color: @cyan;
      color: #fff;
    }
  }

  .intro-section {
    width: 340px;
    margin: 10px auto 20px;
    
    .intro {
      width: 100%;
      font-size: 15px;
      line-height: 24px;
      text-align: justify;
      padding-top: 20px;
      color: #5C5C5C;
      
      h1 {
        font-size: 20px;
        margin-bottom: 15px;
        text-align: center;
        color: #060211;
      }
    }
    
    .intro-img {
      width: 100%;
      height: 120px;
      margin: 20px 0;
      
      .intro-img-right {
        width: auto;
        height: 100%;
        border-radius: 2px;
      }
      
      .intro-img-left {
        width: auto;
        height: 100%;
        border-radius: 2px;
      }
    }
    
    .top-video {
      margin-top: 6px;
      width: 100%;
      height: 192px;
      background-color: #fff;
      border-radius: 4px;
      overflow: hidden;
    }
    
  }
  
  .section-box {
    width: 375px;
    margin: 0 auto;
    padding: 24px 10px;
    
    .title {
      margin-bottom: 20px;
      font-size: 20px;
      font-family: 'Poppins-Bold';
      text-align: center;
    }
    
    .category-box {
      margin-bottom: 20px;
      
      .category-name {
        margin-bottom: 5px;
        font-size: 16px;
        font-family: 'Poppins-Medium';
        text-align: center;
      }
      
      .category-img-list {
        width: 100%;
        flex-wrap: wrap;
        
        .category-img-box {
          width: 50%;
          padding: 10px;
          
          .category-img {
            width: 100%;
            height: auto;
            border-radius: 4px;
          }
        }
      }
      
    }
    
  }

  .section {
    padding: 24px 20px;
    
    .title-box {
      display: none;
    }
    .theme-title,
    .title {
      width: 100%;
      margin-bottom: 10px;
      font-size: 20px;
      font-family: 'Poppins-Bold';
      text-align: center;
    }
    
    .more {
      margin: 15px auto;
      width: 90px;
      line-height: 24px;
      font-size: 12px;
      text-align: center;
      color: #fff;
      background-color: @cyan;
      border-radius: 2px;
      cursor: pointer;
    }
    
    .turn-btn-box ,
    .scroll-contianer,
    .scroll-contianer2,
    .theme-scroll-contianer {
      display: none;
    }
    
    .scroll-contianer1 {
      width: 100%;
      
      .item,
      .item1,
      .item2 {
        display: inline-block;
        width: 100%;
        
        .item-title {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          line-height: 36px;
          font-size: 18px;
          color: #fff;
          text-align: center;
          font-family: 'Poppins-Bold';
          letter-spacing: 2Px;
        }
        
        .exh-img {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 4px 4px 0 0;
        }
        
        .intro-box1,
        .intro-box2,
        .intro-box3 {
          width: 100%;
          overflow: hidden;
          border-radius: 0 0 4px 4px;
          padding: 10px 0;
          color: #060211;
          
          h2 {
            padding: 0 8px;
            margin-bottom: 6px;
            font-family: 'Poppins-Medium';
            font-size: 14px;
            text-align: left;
          }
        }
        
        .intro-box1 {
          background-color: #fff;
        }
        .intro-box2 {
          background-color: #FAFAFA;
        }
        .intro-box3 {
          background-color: #060211;
          color: #fff;
        }
        
        
        .intro,
        .intro1 {
          display: block;
          width: 100%;
          box-sizing: border-box;
          padding: 0 8px;
          margin-top: 2px;
          font-size: 14px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display:-webkit-box;
          -webkit-line-clamp:2;
          -webkit-box-orient:vertical;
        }
        
        .intro1 {
          height: 16px;
          
          h2 {
            padding: 2px 5px 0;
            line-height: 8px;
            font-weight: bold;
            font-size: 3.2px;
            text-align: left;
          }
        }
      }
      .item1 {
        position: relative;
        height: auto;
      }
      
      .item2 {
        display: flex;
        height: auto;
        padding: 0;
      }
      .item2:first-child {
        margin-left: 4px;
      }
      
    }
    
    .guest-img {
      width: 100px;
      height: 100px;
      margin: 0 10px 10px 0;
      border-radius: 4px;
      overflow: hidden;
    }
    
    .guest-name {
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
      color: #fff;
      line-height: 30Px;
      border-radius: 0 0 4px 4px;
      padding: 0 5px;
      width: 100%;
      font-size: 12px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    
    .play-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 20px;
      height: 7px;
      margin-left: 6px;
      padding: 0 4px;
      border: none;
      font-size: 3.2px;
      border-radius: 1.2px;
    }
    
  }
  
  .tabs-bar {
    margin: 0 0 20px;
    
    .tabs {
      
      .tab {
        height: 24px;
        font-size: 16px;
        font-family: 'Poppins-Bold';
        margin: 0 10px;
        
        .cur {
          width: 100%;
          height: 3px;
          margin-top: 2px;
          background-color: #060211;
          border-radius: 2px;
        }
      }
      
    }
    
  }
  

  .scroll-contianer0,
  .scroll-contianer3 {
    width: 100%;
    border-radius: 1.2px;
    
    .item {
      display: inline-block;
      width: 100%;
      // height: auto;
      // overflow: hidden;
      
      .news-img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 4px;
      }
        
      h2 {
        width: 100%;
        padding: 5px;
        line-height: 20px;
        font-family: 'Poppins-Bold';
        font-size: 14px;
        text-align: left;
      }
      
    }
    
  }
  
  .video-container {
    margin-top: 6px;
    margin-bottom: 20px;
    width: 100%;
    height: 200px;
    background-color: #fff;
    border-radius: 1px;
    overflow: hidden;
  }
  
  .downcount-box {
    margin-bottom: 20px;
    text-align: center;
    
    .noties {
      margin: 0 auto;
      width: 290px;
      font-size: 16px;
      font-family: 'Poppins-Bold';
    }
    .day {
      font-family: 'Poppins-Bold';
      font-size: 20px;
      margin: 10px auto;
      
      .downcount-day {
        color: @cyan;
        padding: 0 4px;
        font-size: 36px;
        font-family: 'Poppins-Bold';
        padding-right: 15px;
      }
      
    }
  }
  
}

// PC端
@media screen and (min-width: 1024px) {
  
  .date,
  .sign-btn-box1 {
    display: none;
  }
  
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
    
    .custom-banner-indicator {
      position: absolute;
      right: 6px;
      bottom: 6px;
      padding: 1px;
      
      .btn-box {
        margin: 0 1px;
        padding: 1px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 1px;
        font-size: 8px;
        
        &.left {
          transform: rotate(90deg);
        }
        &.right {
          transform: rotate(-90deg);
        }
      }
    }
    
  }
  
  .sign-btn-box {
    right: 16%;
    bottom: 43%;
    
    // left: 9%;
    // bottom: 5%;
    
    z-index: 5;
    
    .button {
      font-family: 'Poppins-Light';
      display: block;
      background-color: @purplish-red;
      color: #fff;
      border: none;
      width: 62px;
      padding: 3px 0;
      // margin: 6px 0;
      margin: 6px 6px 6px 0;
      font-size: 6px;
      text-align: center;
      border-radius: 1.2px;
      cursor: pointer;
    }
    .reg-btn {
      background-color: @cyan;
      color: #fff;
    }
  }
  
  .sign-btn-box2 {
    justify-content: center;
    
    .button {
      font-family: 'Poppins-Light';
      display: block;
      background-color: @purplish-red;
      color: #fff;
      border: none;
      width: 70px;
      padding: 4px 0;
      margin: 6px 10px;
      font-size: 6px;
      text-align: center;
      border-radius: 1.2px;
      cursor: pointer;
    }
    
    .reg-btn {
      background-color: @cyan;
      color: #fff;
    }
  }
  
  .intro-section {
    width: 350px;
    margin: 20px auto 10px;
    display: flex;
    justify-content: center;
    
    .intro {
      width: 120px;
      font-size: 3.3px;
      line-height: 1.6em;
      text-align: justify;
      margin-right: 10px;
      
      h1 {
        font-size: 5px;
        margin-bottom: 5px;
      }
    }
    
    .intro-img {
      width: auto;
      height: 75px;
      
      .intro-img-right {
        width: auto;
        height: 100%;
        border-radius: 1px;
      }
      
      .intro-img-left {
        width: auto;
        height: 100%;
        border-radius: 1px;
        margin-right: 5px;
      }
    }
    
    .top-video {
      width: 138px;
      height: 78px;
      // width: 200px;
      // height: 113px;
      background-color: #fff;
      border-radius: 2px;
      overflow: hidden;
    }
    
  }
  
  .section-box {
    width: 360px;
    margin: 0 auto;
    padding: 10px 20px 24px;
    
    .title {
      margin-bottom: 10px;
      font-size: 7px;
      font-family: 'Poppins-Bold';
      text-align: center;
    }
    
    .category-box {
      margin-bottom: 12px;
      
      .category-name {
        margin-bottom: 5px;
        font-size: 6px;
        font-family: 'Poppins-Medium';
        text-align: center;
      }
      
      .category-img-list {
        width: 100%;
        gap: 5px;
        
        .category-img-box {
          flex: 1;
          
          .category-img {
            width: 100%;
            height: auto;
            border-radius: 1px;
          }
        }
      }
      
    }
    
  }

  
  .section {
    padding: 15px 20px;
    
    .section-container {
      display: flex;
      // justify-content: center;
    }
    
    .title-box1 {
      display: none;
    }
    .more {
      margin: 10px  0 10px 48px;
      width: 36px;
      line-height: 10px;
      font-size: 4px;
      text-align: center;
      color: #fff;
      background-color: @cyan;
      border-radius: 1px;
      cursor: pointer;
    }
    .more1 {
      margin: 10px auto;
    }
    
    .title-box {
      .map-title {
        font-size: 6px;
        font-family: 'Poppins-Bold';
      }
    }
    
    .video-title {
      width: 24px;
      font-size: 5px;
      font-weight: bold;
    }
    
    .title {
      margin-right: 10px;
      width: 36px;
      font-size: 7px;
      font-family: 'Poppins-Bold';
    }
    .theme-title {
      width: 100%;
      font-size: 7px;
      text-align: center;
      margin-bottom: 6px;
      font-family: 'Poppins-Bold';
    }
    
    .scroll-contianer1 {
      display: none;
    }
    
    .theme-scroll-contianer {
      width: 100%;
      border-radius: 1.2px;
      
      /deep/ .van-swipe-item {
        width: 100px!important;
      }
      
      .item1 {
        display: inline-block;
        width: 100%;
        // height: 110px;
        padding: 0 2px;
        box-sizing: border-box;
        
        .exh-img {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 1px 1px 0 0;
        }
        
      }
      .item1 {
        position: relative;
        height: auto;
      }
      
      .turn-btn-box {
        display: none;
        right: 0;
        top: 50%;
        z-index: 1;
        transform: translateY(-5px);
        width: 100%;
        box-sizing: border-box;
        padding: 0 4px 0 6px;
        
        .btn-box,
        .btn-box1 {
          margin: 0 1px;
          padding: 1px;
          background: rgba(255, 255, 255, 0.7);
          border-radius: 40px;
          font-size: 7px;
          
          &.left {
            transform: rotate(90deg);
            float: left;
          }
          &.right {
            transform: rotate(-90deg);
            float: right;
          }
        }
        
        .btn-box1 {
          background-color: rgba(0, 0, 0, .6);
          color: #fff;
        }
      }
      
      &:hover {
        .turn-btn-box {
          display: block;
        }
      }
    }
    
    .scroll-contianer {
      width: 270px;
      border-radius: 1.2px;
      
      /deep/ .van-swipe-item {
        width: 100px!important;
      }
      
      .item,
      .item1,
      .item2 {
        display: inline-block;
        width: 100%;
        // height: 110px;
        padding: 0 2px;
        box-sizing: border-box;
        
        .item-title {
          position: absolute;
          left: 10px;
          bottom: 8px;
          font-size: 5px;
          color: #fff;
          font-weight: bold;
          letter-spacing: 2Px;
        }
        
        &:hover {
          .exh-img,
          .intro-box1,
          .intro-box2,
          .intro-box3 {
            box-shadow: 0 .1px 2px rgba(0, 0, 0, .1);
          }
        }
        
        .exh-img {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 1px 1px 0 0;
        }
        
        .intro-box1,
        .intro-box2,
        .intro-box3 {
          width: 100%;
          height: 40px;
          overflow: hidden;
          box-sizing: border-box;
          border-radius: 0 0 1px 1px;
          padding-bottom: 6px;
          color: #060211;
          
          h2 {
            padding: 2px 5px 0;
            line-height: 8px;
            font-weight: bold;
            font-size: 3.2px;
            text-align: left;
          }
        }
        
        .intro-box1 {
          background-color: #fff;
        }
        .intro-box2 {
          background-color: #FAFAFA;
        }
        .intro-box3 {
          background-color: #060211;
          color: #fff;
        }
        
        
        .intro,
        .intro1 {
          display: block;
          width: 100%;
          height: 24px;
          box-sizing: border-box;
          padding: 0 5px;
          margin-top: 2px;
          line-height: 5px;
          font-size: 3px;
          text-align: justify;
          overflow: hidden;
        }
        
        .intro1 {
          height: 16px;
          
          h2 {
            padding: 2px 5px 0;
            line-height: 8px;
            font-weight: bold;
            font-size: 3.2px;
            text-align: left;
          }
        }
      }
      .item1 {
        position: relative;
        height: auto;
      }
      
      .item2 {
        display: flex;
        height: auto;
        padding: 0;
      }
      .item2:first-child {
        margin-left: 4px;
      }
      
      .turn-btn-box {
        display: none;
        right: 0;
        top: 50%;
        z-index: 1;
        transform: translateY(-5px);
        width: 100%;
        box-sizing: border-box;
        padding: 0 4px 0 6px;
        
        .btn-box,
        .btn-box1 {
          margin: 0 1px;
          padding: 1px;
          background: rgba(255, 255, 255, 0.7);
          border-radius: 40px;
          font-size: 7px;
          
          &.left {
            transform: rotate(90deg);
            float: left;
          }
          &.right {
            transform: rotate(-90deg);
            float: right;
          }
        }
        
        .btn-box1 {
          background-color: rgba(0, 0, 0, .6);
          color: #fff;
        }
      }
      
      &:hover {
        .turn-btn-box {
          display: block;
        }
      }
    }
    
    .guest-img {
      width: 46px;
      height: 46px;
      margin: 0 4px 4px 0;
      border-radius: 0.8px;
      overflow: hidden;
    }
    
    .guest-name {
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
      color: #fff;
      line-height: 10px;
      border-radius: 0 0 1px 1px;
      padding: 0 2px;
      width: 100%;
      font-size: 4px;
      text-align: center;
    }
    
    .play-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 20px;
      height: 7px;
      margin-left: 6px;
      padding: 0 4px;
      border: none;
      font-size: 3.2px;
      border-radius: 1.2px;
    }
    
  }
  
  .tabs-bar {
    margin: 0 0 10px;
    
    .tabs {
      
      .tab {
        height: 10px;
        font-size: 6px;
        font-weight: bold;
        margin: 0 8px;
        cursor: pointer;
        
        .cur {
          width: 100%;
          height: 1px;
          margin-top: 1.2px;
          background-color: #060211;
          border-radius: 1px;
        }
      }
      
    }
    
    .news-more {
      font-size: 3.6px;
      padding-top: 2px;
      right: 12%;
      cursor: pointer;
      color: #999;
    }
  }
  
  .scroll-contianer0,
  .scroll-contianer3 {
    display: none;
  }
  
  .scroll-contianer2 {
    // width: 80%;
    width: 270px;
    border-radius: 1.2px;
    
    /deep/ .van-swipe-item {
      width: 112px!important;
    }
    
    .item {
      position: relative;
      display: inline-block;
      width: 108px;
      height: auto;
      overflow: hidden;
      
      &:hover {
        .intro-box {
          bottom: 0;
        }
      }
      
      .intro-box {
        position: absolute;
        left: 0;
        bottom: -20px;
        width: 100%;
        height: 20px;
        height: auto;
        box-sizing: border-box;
        border-radius: 0 0 1.2px 1.2px;
        color: #fff;
        background-color: rgba(0, 0, 0, .4);
        transition: bottom 0.2s;
        
        h2 {
          display: flex;
          align-items: center;
          padding: 4px;
          line-height: 6px;
          font-weight: bold;
          font-size: 3.2px;
          text-align: left;
        }
      }
      
    }
    
    .turn-btn-box {
      display: none;
      right: 0;
      top: 50%;
      z-index: 1;
      transform: translateY(-8px);
      width: 100%;
      box-sizing: border-box;
      padding: 0 1px;
      
      .btn-box {
        margin: 0 1px;
        padding: 1px;
        background-color: rgba(0, 0, 0, .6);
        color: #fff;
        border-radius: 20px;
        font-size: 7px;
        
        &.left {
          transform: rotate(90deg);
          float: left;
        }
        &.right {
          transform: rotate(-90deg);
          float: right;
        }
      }
      
    }
    
    &:hover {
      .turn-btn-box {
        display: block;
      }
    }
  }

  .news-img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 1.2px 1.2px 0 0;
  }
  
  .video-container {
    margin-top: 6px;
    margin-bottom: 6px;
    width: 330px;
    height: 186px;
    background-color: #fff;
    border-radius: 1px;
    overflow: hidden;
  }
  
  .downcount-box {
    margin-bottom: 10px;
    
    .noties {
      font-size: 8px;
      font-family: 'Poppins-Bold';
      margin-bottom: 5px;
    }
    .day {
      font-family: 'Poppins-Bold';
      font-size: 12px;
      text-align: center;
      margin-bottom: 4px;
      
      .downcount-day {
        color: @cyan;
        padding: 0 8px;
        font-size: 18px;
        font-family: 'Poppins-Bold';
      }
      
    }
    
  }
  
}
  
</style>
<template>
  <div class="footer-bottom">
    
    <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/en/img/white.png" class="logo-white" alt="SZCW Shenzhen China International Furniture Exhibition & Creative Week logo" />
    <div class="bottom-wrap">
      <div class="units-qr">
        <div class="left">
          <div class="zd-units">
            <div class="h4">Supervisory Units</div>
            <ul class="ul2">
              <li>Commerce Bureau of Shenzhen Municipality</li>
              <li>Industry and Information Technology Bureau of Shenzhen Municipality</li>
            </ul>
          </div>
          <div class="zb-units">
            <ul>
              <li>
                <div class="name">Organizers</div>
                <img :src="imgUrl + '/2021/12/footer/unit-1-eea86a5ac2d2b5a19c6c0e628062e5e635197fc493137a46702a3a204ac08fc5.png'" class="img1" alt="SZFA Shenzhen Furniture Association logo - SZCW Expo Organizer">
              </li>
              <li>
                <div class="name">&nbsp;</div>
                <img :src="imgUrl + '/2021/01/footer/jrzj-96a385c738be330a9cbcabdff7730fd6a886968eb499af46da1d032cd64e52bf.png'" class="img2" alt="Easyhome Logo - SZCW Expo Organizer">
              </li>
              <li>
                <div class="name">&nbsp;</div>
                <img :src="imgUrl + '/2021/01/footer/fsm-afcb6e678513ee7406f885fa050d0b24b8eacc0983627321f91bbc90b052ceac.png'" class="img3" alt="Fusen Noble-House Logo - - SZCW Expo Organizer">
              </li>
              <li>
                <div class="name">Co-Operator</div>
                <img :src="imgUrl + '/2021/12/footer/unit-2-2fbd6c353346411a7179916b19afb611938a3143f5fd071649cc0de1aed7c913.png'" class="img4" alt="Creative Design Alliance Logo - - SZCW Co-operator">
              </li>
              <li>
                <div class="name">Operator</div>
                <img :src="imgUrl + '/2021/12/footer/unit-3-9d80e5e0c94677bd4f0756ef104231fb56aee97712c8035cce08daa3c9f7dadd.png'" class="img5" alt="Desy Exhibition Logo - - SZCW Organizer">
              </li>
              <li>
                <div class="name">Technical Support</div>
                <img :src="imgUrl + '/2021/12/footer/unit-4-fd2b799a5e8347ef62d0cdbd7fa51374481c5c3796c35607d3cd40045d3f19b3.png'" class="img6" alt="Shenzhen Furniture Design Institute Logo - SZCW Expo Organizer">
              </li>
              <li>
                <div class="name">Quality Control</div>
                <img :src="imgUrl + '/2021/12/footer/unit-5-8aa9f30e5a312f2590a9fe97280ddf5336d070d73cd26e38368fe108d3a61a70.png'" class="img7" alt="Saide Testing Logo - SZCW Expo Organizer">
              </li>
            </ul>
    
          </div>
        </div>
        <div class="right">
          <div class="qr">
            <div class="h4">Contact Us</div>
            <div class="label"><span class="tt">Service Inquiry :</span><br><span>luoliting@szfa.com</span></div>
            <div class="label"><span class="tt">Business Cooperation :</span><br><span>business-cooperation@szfa.com</span></div>
            <div class="label"><span class="tt">Booth Application :</span><br><span>timingpan@szfa.com</span></div>

            <!-- <img class="" :src="imgUrl + '/2017/10/qr-95407ca59948a7cab4724041311a8ef99820596b69dcb183ccac662306cdda66.png'" alt="Contact us QR code for SZCW"> -->
          </div>
        </div>
      </div>
      
      <div v-if="friendLink.length > 0" class="friend-link-contianer">
        <span class="link-title">Friendly Links:</span>
        <div class="link-box">
          <a v-for="(link, idx) in friendLink" :key="idx" :href="link.url" target="_blank" rel="noopener noreferrer" class="link-item">{{link.name}}</a>
        </div>
      </div>
      
      <div class="b-rights">
        <p> ©COPYRIGHT Shenzhen Desy Exhibition Co., Ltd. All rights reserved </p>
        <p>Organizer's Address: Shenzhen Furniture Research and Development Base, No. 8 Tongfa Road, Xili, Nanshan District, Shenzhen City</p>
      </div>
    </div>

  </div>

</template>

<script>
  export default {
    name: '',
    props: {
      friendLink: {
        type: Array,
        default: function() {
          return []
        }
      },
    },
    data() {
      return {
        imgUrl: 'https://sife.oss-cn-shenzhen.aliyuncs.com/assets/themes/szcw/assets/images/uploads',
      }
    },
    created() {

    },
    methods: {

    },
  }
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .footer-bottom {
    position: relative;
    background-image: linear-gradient(180deg, #0d0d0d, #002230);
    padding-top: 70px;
    padding-bottom: 20px;
  }
  
  .logo-white {
    position: absolute;
    z-index: 9;
    left: 7%;
    top: 24px;
    width: 160px;
    height: auto;
    margin-bottom: 8px;
  }
  .logo-white1 {
    width: 160px;
    height: auto;
    margin-bottom: 8px;
  }
  
  .bottom-wrap {
    width: 86%;
    margin: 0 auto;
    margin-bottom: 0;
  }
  
  .zd-units {
    padding-top: 35px;
    
    .h4 {
      font-size: 12px;
      line-height: 4.8px;
      color: #9b9fa2;
      margin-bottom: 5px;
    }
  
    .ul1 {
      display: flex;
      color: #fff;
      font-size: 12px;
      
      li {
        margin-right: 20px;
      }
    }
    .ul2 {
      color: #fff;
      font-size: 12px;
      width: 50%;
      li {
        margin: 10px 0;
      }
    }
  
  }
  .zb-units {
    margin-top: 20px;
    margin-bottom: 12px;
    
    .name {
      font-size: 10px;
      padding-bottom: 2px;
      color: #9b9fa2;
    }
    
    ul {
      display: flex;
      flex-wrap: wrap;
      
      li {
        margin-right: 20px;
        margin-bottom: 10px;
      }
      
      .img1 {
        width: 40px;
        height: auto;
      }
      .img2 {
        width: 60px;
        height: auto;
      }
      .img3 {
        width: 80px;
        height: auto;
      }
      .img4 {
        width: 40px;
        height: auto;
      }
      .img5 {
        width: 70px;
        height: auto;
      }
      .img6 {
        width: 110px;
        height: auto;
      }
      .img7 {
        width: 70px;
        height: auto;
      }
    }
  }
  .units-qr {
    display: flex;
    justify-content: space-between;
    
    .right {
      position: absolute;
      right: 0.1rem;
      top: 1.2rem;
      width: 43%;
      z-index: 1;
      
      .h4 {
        font-size: 12px;
        color: #fff;
        //text-align: center;
        margin-bottom: 0px;
      }
      .label{
        color:white;font-size: 0.08533rem;margin-top: 0.3rem;
      }
      .tt{
        font-weight:900;color: #9b9fa2;
      }
      // img {
      //   width: 70px;
      //   height: auto;
      // }
    }
  
  
  }
  
  .b-rights {
    padding-top: 15px;
    font-size: 10px;
    line-height: 18px;
    color: #9b9fa2;
    border-top: 0.1px solid #808080;
    text-align: center;
    
    a {
      font-size: 10px;
      color: #9b9fa2;
    }
  }

  .friend-link-contianer {
    margin-bottom: 20px;
    
    .link-title {
      display: block;
      width: 280px;
      color: #9b9fa2;
      font-size: 12px;
      margin-bottom: 8px;
    }
    
    .link-box {
      display: flex;
      flex-wrap: wrap;
      
      .link-item {
        display: block;
        width: auto;
        color: #9b9fa2;
        font-size: 12px;
        margin: 0 10px 5px 0;
      }
    }
  }
  
}

// PC端
@media screen and (min-width: 1024px) {
  
  .footer-bottom {
    position: relative;
    background-image: linear-gradient(180deg, #0d0d0d, #002230);
    padding-top: 20px;
    padding-bottom: 7px;
  }
  
  .logo-white {
    position: absolute;
    z-index: 10;
    left: 11%;
    top: 12px;
    width: 72px;
    height: auto;
    margin-bottom: 8px;
  }
  .logo-white1 {
    width: 72px;
    height: auto;
    margin-top: 10px;
  }

  .bottom-wrap {
    // max-width: 78%;
    width: 290px;
    margin: 0 auto;
    margin-bottom: 0;
  }

  .name {
    font-size: 3px;
    padding-bottom: 2px;
    color: #9b9fa2;
  }
  .zd-units {
    padding-top: 16.5px;
    
    .h4 {
      font-size: 3.2px;
      line-height: 4.8px;
      color: #9b9fa2;
      margin-bottom: 1px;
    }

    ul {
      display: flex;
      color: #fff;
      font-size: 3.2px;
      
      li {
        margin-right: 6.6px;
      }
    }

  }
  .zb-units {
    margin-top: 8px;
    margin-bottom: 12px;
    
    ul {
      display: flex;
      font-size: 3px;
      
      li {
        margin-right: 9px;
      }
      
      .img1 {
        width: 15px;
        height: auto;
      }
      .img2 {
        width: 20px;
        height: auto;
      }
      .img3 {
        width: 24px;
        height: auto;
      }
      .img4 {
        width: 12px;
        height: auto;
      }
      .img5 {
        width: 20px;
        height: auto;
      }
      .img6 {
        width: 25px;
        height: auto;
      }
      .img7 {
        width: 20px;
        height: auto;
      }
    }
  }
  .units-qr {
    display: flex;
    justify-content: space-between;
    
    .right {
      position: relative;
      width: 60px;
      
      .qr {
        position: relative;
        z-index: 1;
        .h4 {
          padding-bottom: 0px;
          padding-top: 11px;
          font-size: 3.2px;
          color: #fff;
        }
        .label{
          color:white;font-size: 0.08533rem;margin-top: 0.05rem;
        }
        .tt{
          font-weight:900;color: #9b9fa2;
        }
        // img {
        //   width: 26px;
        //   height: auto;
        // }
      }
    }


  }

  .b-rights {
    padding-top: 8px;
    font-size: 3.3px;
    line-height: 4.8px;
    color: #9b9fa2;
    border-top: 0.1px solid #808080;
    text-align: center;
    
    a {
      font-size: 3.3px;
      color: #9b9fa2;
    }
  }
  
  .friend-link-contianer {
    // display: flex;
    margin-bottom: 5px;
    
    .link-title {
      display: inline-block;
      width: 20px;
      color: #9b9fa2;
      font-size: 3.6px;
      vertical-align: top;
    }
    
    .link-box {
      display: inline-block;
      width: 265px;
      
      .link-item {
        display: inline-block;
        min-width: 5px;
        width: auto;
        color: #9b9fa2;
        font-size: 3.6px;
        margin: 0 3px 2px 0;
      }
    }
  }
  
}
</style>
